<template>
  <div>
    <el-card>
      <div class="flex flex-align-center margin-bottom-20">
        <div class="label">支付时间：</div>
        <el-date-picker
          :clearable="false"
          v-model="payTime"
          value-format="yyyy-MM-dd"
          format="yyyy-MM-dd"
          type="daterange"
          range-separator="至"
          start-placeholder="起始时间"
          end-placeholder="结束时间"
          :picker-options="options"
          >
        </el-date-picker>
      </div>
      <div class="flex flex-align-center margin-bottom-20">
        <div class="label">金额范围：</div>
        <el-select v-model="moeneyType" class="margin-right-10" style="width:150px">
          <el-option v-for="(v,i) in moeneyTypeList" :key="i" :value="v.value" :label="v.label"></el-option>
        </el-select>
        <el-input v-model="minMoney" placeholder="最小金额" style="width:200px"
         oninput="value=((value.replace(/[^0-9.]/g,'')).replace(/([0-9]+\.[0-9]{2})[0-9]*/,'$1'))">
          <template slot="append">元</template>
        </el-input>
        <span style="margin:0px 10px">~</span>
        <el-input v-model="maxMoney" placeholder="最大金额" style="width:200px"
         oninput="value=((value.replace(/[^0-9.]/g,'')).replace(/([0-9]+\.[0-9]{2})[0-9]*/,'$1'))">
          <template slot="append">元</template>
        </el-input>
      </div>
      <div class="flex flex-align-center margin-bottom-20">
        <div class="label">数量范围：</div>
        <el-select v-model="numType" class="margin-right-10" style="width:150px">
          <el-option v-for="(v,i) in numTypeList" :key="i" :value="v.value" :label="v.label"></el-option>
        </el-select>
        <el-input v-model="minNum" placeholder="最小笔数" style="width:200px"
         oninput="value=((value.replace(/[^0-9]/g,'')).replace(/([0-9]+\.[0-9]{2})[0-9]*/,'$1'))">
          <template slot="append">笔</template>
        </el-input>
        <span style="margin:0px 10px">~</span>
        <el-input v-model="maxNum" placeholder="最大笔数" style="width:200px"
          oninput="value=((value.replace(/[^0-9]/g,'')).replace(/([0-9]+\.[0-9]{2})[0-9]*/,'$1'))">
          <template slot="append">笔</template>
        </el-input>
        <el-button type="primary" style="margin-left:26px" @click="search(1)">查询</el-button>
      </div>
    </el-card>
    <el-card style="margin-top:10px">
      <div v-if="(tablelist&&tablelist.length)||searchType==2">
        <div class="flex flex-align-center margin-bottom-20">
          <div class="label">关键字：</div>
          <el-select v-model="keyType" class="margin-right-10" style="width:150px">
            <el-option v-for="(v,i) in keyTypeList" :key="i" :value="v.value" :label="v.label"></el-option>
          </el-select>
          <el-input v-model="keywords" style="width:200px" :placeholder="keyLabel"></el-input>
          <el-button type="primary" style="margin-left:10px" @click="search(2)">查询</el-button>
          <div class="flex flex-align-center" style="margin-left:auto">
            <el-button type="text" @click="tosummary">客户商品购买汇总</el-button>
            <el-popover placement="top" trigger="hover">
              <div style="width:200px">查看符合条件的客户在筛选的时间范围内都购买了什么商品</div>
              <i slot="reference" class="el-icon-question" style="color:#CCCCCCFF;margin-left:5px;margin-top:3px"></i>
            </el-popover>
          </div>
        </div>
        <el-table :data="tablelist" v-loading="tableloading" style="margin-top:10px" @sort-change="sortChange">
          <el-table-column prop="" label="客户" width="470">
            <template slot-scope="scope">
              <div class="flex flex-align-center">
                <img :src="scope.row.WxHeadUrl||defaultHeader" style="width:48px;height:48px;border-radius:3px;flex-shrink:0" alt="">
                <div style="width:0;flex:1;margin-left:5px">
                  <div class="ellipsis blueFont" @click="todetail(scope.row)">{{scope.row.WxNickname}}
                    <span v-if="scope.row.Name">({{scope.row.Name}})</span>
                  </div>
                  <div>{{scope.row.Phone}}</div>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="PayMoney" label="支付金额(元)" sortable="custom"></el-table-column>
          <el-table-column prop="RealPayMoney" label="实际成交金额(元)" sortable="custom"></el-table-column>
          <el-table-column prop="PayOrderCount" label="支付笔数(笔)" sortable="custom"></el-table-column>
          <el-table-column prop="RealPayOrderCount" label="实际成交笔数(笔)" sortable="custom"></el-table-column>
          <el-table-column prop="" label="操作" width="100">
            <template slot-scope="scope">
              <el-button type="text" @click="detail(scope.row)">消费明细</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div style="margin-top:10px;text-align:right" v-if="total">
          <el-pagination
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-size="pagesize"
            layout="total, prev, pager, next, jumper"
            :total="total">
          </el-pagination>
        </div>
      </div>
      <div v-else-if="searchType==1&&(!tablelist||!tablelist.length)" style="font-size:14px;color:#606266;text-align:center;padding:40px 0px" v-loading="tableloading">
        {{isSearch?'未找到符合条件的客户数据，请修改筛选条件':'暂无数据，请使用上方筛选条件，查询相应客户数据'}}
      </div>
    </el-card>

    <el-dialog :visible.sync="summaryShow" title="客户商品购买汇总" custom-class="dialog-body-paddingTop-10" width="1000px" @close="closesummary">
      <div class="flex flex-align-center" style="color:#999999FF;font-size:14px">
        <i class="el-icon-warning"></i>
        <span style="margin-left:3px">查看符合条件的客户在筛选的时间范围内都购买了什么商品</span>
      </div>
      <el-table :data="summaryTablelist" v-loading="summaryLoading" max-height="500" style="margin-top:10px">
        <el-table-column prop="" label="商品" min-width="150">
          <template slot-scope="scope">
            <div class="flex flex-align-center">
              <img :src="scope.row.ImgUrlComplete" style="width:48px;height:48px;border-radius:3px;flex-shrink:0" alt="">
              <div style="width:0;flex:1;margin-left:5px">
                <div class="ellipsis">{{scope.row.Name}}</div>
                <div style="color:#999999FF">{{scope.row.ProductNo}}</div>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="PayMoney" label="支付金额(元)"></el-table-column>
        <el-table-column prop="RealPayMoney" label="实际成交金额(元)"></el-table-column>
        <el-table-column prop="PayCount" label="购买数量(件)"></el-table-column>
        <el-table-column prop="RealPayCount" label="实际成交数量(件)"></el-table-column>
      </el-table>
      <div style="margin-top:10px;text-align:right" v-if="total2">
        <el-pagination
          @current-change="handleCurrentChange2"
          :current-page="currentPage2"
          :page-size="pagesize2"
          layout="total, prev, pager, next, jumper"
          :total="total2">
        </el-pagination>
      </div>
    </el-dialog>

    <el-dialog :visible.sync="detailShow" title="客户消费明细" custom-class="dialog-body-paddingTop-10" width="1000px" @close="closedetail">
      <div class="flex flex-align-center">
        <img :src="rowmsg.WxHeadUrl||defaultHeader" style="width:48px;height:48px;border-radius:3px;flex-shrink:0" alt="">
        <div style="margin-left:5px;max-width:400px">
          <div class="ellipsis">{{rowmsg.WxNickname}}<span v-if="rowmsg.Name">({{rowmsg.Name}})</span></div>
          <div></div>
        </div>
        <div style="font-size:14px;margin-left:20px;margin-right:auto">
          支付时间：{{payTime[0]}}~{{payTime[1]}}
        </div>
      </div>
      <el-table :data="detailTablelist" v-loading="detailLoading" max-height="500" style="margin-top:10px">
        <el-table-column prop="" label="商品" min-width="150">
          <template slot-scope="scope">
            <div class="flex flex-align-center">
              <img :src="scope.row.ImgUrlComplete" style="width:48px;height:48px;border-radius:3px;flex-shrink:0" alt="">
              <div style="width:0;flex:1;margin-left:5px">
                <div class="ellipsis">{{scope.row.Name}}</div>
                <div style="color:#999999FF">{{scope.row.ProductNo}}</div>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="PayMoney" label="支付金额(元)"></el-table-column>
        <el-table-column prop="RealPayMoney" label="实际成交金额(元)"></el-table-column>
        <el-table-column prop="PayCount" label="购买数量(件)"></el-table-column>
        <el-table-column prop="RealPayCount" label="实际成交数量(件)"></el-table-column>
        <el-table-column prop="LastPayTime" label="最近一次购买时间" width="160"></el-table-column>
      </el-table>
      <div style="margin-top:10px;text-align:right" v-if="total3">
        <el-pagination
          @current-change="handleCurrentChange3"
          :current-page="currentPage3"
          :page-size="pagesize3"
          layout="total, prev, pager, next, jumper"
          :total="total3">
        </el-pagination>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import config from "@/config"
import {
  CustomercontributionRank,
  CustomercustomerConsumeDetail,
  CustomercustomerBuyCollect
} from "@/api/TurnTomySelf"
import {
  nextDay
} from "@/utils/index"
export default {
  data () {
    return {
      payTime:[],
      options:{
        disabledDate:(e)=>{
          return e.getTime() > new Date().getTime()
        }
      },
      moeneyType:0,
      moeneyTypeList:[
        {value:0,label:'支付金额'},
        {value:1,label:'实际成交金额'},
      ],
      minMoney:'',
      maxMoney:'',
      numType:0,
      numTypeList:[
        {value:0,label:'支付笔数'},
        {value:1,label:'实际成交笔数'},
      ],
      minNum:'',
      maxNum:'',
      isSearch:false,
      keyType:0,
      keyTypeList:[
        {value:0,label:'微信昵称'},
        {value:1,label:'姓名'},
        {value:2,label:'手机号'},
      ],
      keywords:'',
      defaultHeader:config.DEFAULT_HEADER,
      tablelist:[],
      tableloading:false,
      sortType:{
        order:'',
        prop:''
      },
      currentPage:1,
      pagesize:20,
      total:0,

      summaryShow:false,
      summaryTablelist:[],
      summaryLoading:false,
      currentPage2:1,
      pagesize2:10,
      total2:0,

      rowmsg:{},
      detailShow:false,
      detailTablelist:[],
      detailLoading:false,
      currentPage3:1,
      pagesize3:10,
      total3:0,
      searchType:1,
    }
  },
  computed: {
    keyLabel(){
      return '客户'+this.keyTypeList.find(v=>v.value==this.keyType).label
    }
  },
  methods: {
    closedetail(){
      this.detailShow = false
      this.currentPage3 = 1
      this.total3 = 0
      this.detailTablelist = []
    },
    async getdetaillist(){
      try{
        this.detailLoading = true
        let data = {
          StartTime:this.payTime[0],
          EndTime:nextDay(this.payTime[1],1),
          Id:this.rowmsg.Id,
          Skip:(this.currentPage3-1)*this.pagesize3,
          Take:this.pagesize3,
        }
        let res = await CustomercustomerConsumeDetail(data)
        if(res.IsSuccess){
          this.detailTablelist = res.Result.Results
          this.total3 = res.Result.Total
        }
      }finally{
        this.detailLoading = false
      }
    },
    handleCurrentChange3(e){
      this.currentPage3 = e
      this.getdetaillist()
    },
    closesummary(){
      this.summaryShow = false
      this.currentPage2 = 1
      this.total2 = 0
      this.summaryTablelist = []
    },
    handleCurrentChange2(e){
      this.currentPage2 = e
      this.getsummarylist()
    },
    tosummary(){
      this.summaryShow = true
      this.getsummarylist()
    },
    async getsummarylist(){
      try{
        this.summaryLoading = true
        let data = {
          StartTime:this.payTime[0],
          EndTime:nextDay(this.payTime[1],1),
          Skip:(this.currentPage2-1)*this.pagesize2,
          Take:this.pagesize2,
        }
        let res = await CustomercustomerBuyCollect(data)
        if(res.IsSuccess){
          this.summaryTablelist = res.Result.Results
          this.total2 = res.Result.Total
        }
      }finally{
        this.summaryLoading = false
      }
    },
    search(type){
      this.currentPage = 1
      if(type==1){
        if(!this.payTime||!this.payTime.length){
          this.$message.error('请选择支付时间')
          return
        }
        let star = this.payTime[0]
        let end = this.payTime[1]
        let interval = (new Date(end).getTime() - new Date(star).getTime()) / 1000 / 60 / 60 / 24
        if(interval > 31){
          this.$message.error('最多可查询31天的数据')
          return
        }
        if(this.maxMoney && (Number(this.minMoney) > Number(this.maxMoney))){
          this.$message.error('最小金额需小于等于最大金额')
          return
        }
        if(this.maxNum && (Number(this.minNum) > Number(this.maxNum))){
          this.$message.error('最小数量需小于等于最大数量')
          return
        }
        this.isSearch = true
        this.gettablelist(type)
      }else{
        this.gettablelist(type)
      }
    },
    sortChange(e){
      this.sortType = {
        order:e.order,
        prop:e.prop
      }
      this.gettablelist(2)
    },
    detail(row){
      this.rowmsg = row
      this.detailShow = true
      this.getdetaillist()
    },
    todetail(row){
      window.open(config.GO_URL + '/index.html#/Customer/CustomerDetail?Id=' + row.Id)
    },
    handleCurrentChange(e){
      this.currentPage = e
      this.gettablelist(2)
    },
    async gettablelist(type){
      try{
        this.tableloading = true
        if(type==1){
          this.keyType = 0
          this.keywords = ''
        }
        let data = {
          StartTime:this.payTime[0],
          EndTime:nextDay(this.payTime[1],1),
          MoneyType:this.moeneyType,
          MinMoney:this.minMoney||0,
          MaxMoney:this.maxMoney,
          PayCountType:this.numType,
          MinPayCount:this.minNum||0,
          MaxPayCount:this.maxNum,
          OrderBy:this.sortType.prop,
          IsAsc:this.sortType.order=="ascending",
          Skip:(this.currentPage-1)*this.pagesize,
          Take:this.pagesize,
        }
        if(type==2){
          data = {
            ...data,
            KeywordsType:this.keyType,
            Keywords:this.keywords,
          }
        }
        let res = await CustomercontributionRank(data)
        if(res.IsSuccess){
          this.tablelist = res.Result.Results
          this.total = res.Result.Total
          this.searchType = type
        }
      }finally{
        this.tableloading = false
      }
    },
  }
}
</script>

<style lang="less" scoped>
.flex{
  display: flex;
}
.flex-align-center{
  align-items: center;
}
.flex-wrap{
  flex-wrap: wrap;
}
.label{
  color: #606266;
  font-weight: bold;
  font-size: 14px;
  line-height: 36px;
  flex-shrink: 0;
  white-space: nowrap;
}
.margin-bottom-20{
  margin-bottom: 20px;
}
.margin-right-10{
  margin-right: 10px;
}
.blueFont{
  cursor: pointer;
  color: #409eff;
}
</style>